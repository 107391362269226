import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import MOVIE1 from '../../assets/video/movie1.mp4';
import MOVIE2 from '../../assets/video/movie2.mp4';
import MOVIE3 from '../../assets/video/movie3.mp4';

const VideoWrapper = styled.div`
  opacity: ${props => props.opacity};
  transition: opacity 1s ease-in-out;
  width: 100%;
  height: 100%;
`;

const VideoLoop = () => {
  const videos = [MOVIE1, MOVIE2, MOVIE3];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);

  const handleVideoEnded = () => {
    setOpacity(0); // Iniciar fade out

    setTimeout(() => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
      setOpacity(1); // Iniciar fade in
    }, 1000); // Tempo de transição do fade
  };

  useEffect(() => {
    // Forçar autoplay no carregamento inicial
    const player = document.querySelector('video');
    if (player) {
      player.play().catch((error) => {
        console.log('Autoplay prevented:', error);
      });
    }
  }, [currentVideoIndex]);

  return (
    <VideoWrapper opacity={opacity}>
      <ReactPlayer
        url={videos[currentVideoIndex]}
        playing
        onEnded={handleVideoEnded}
        width="100%"
        height="100%"
        muted={true}
        config={{
          file: {
            attributes: {
              autoPlay: true,
              muted: true, // Garantir que o vídeo está mudo
              playsInline: true // Para iOS Safari
            }
          }
        }}
      />
    </VideoWrapper>
  );
};

export default VideoLoop;

