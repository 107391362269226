import './styles.scss'

const Soon = () => {
    return (
        
        <div class="cloader-area">
            <div class="clface">
                <div class="clsface">
                    <div id="h2" class="hand"></div>
                </div>
                <div class="top"></div>
                <div class="bottom"></div>
                <div class="left"></div>
                <div class="right"></div>
                <div id="sub" class="pin"></div>
                <div id="h1" class="hand"></div>
                <div id="main" class="pin"></div>
            </div>
        </div>
        
    )
}

export default Soon