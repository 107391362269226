import FormArea from '../FormArea';
import './styles.scss';

const HeaderContact = () => {
    return (
        <div className="header-contact-area">
            <FormArea />
        </div>
    );
}

export default HeaderContact;
