import DotsAnimation from '../../common/DotsAnimation'
import './styles.scss'

const data = [
    {
        id: '01',
        title: 'Renderização',
        text: 'Oferecemos imagens realistas que combinam estética e funcionalidade. Os nossos designs visuais são criados para proporcionar uma experiência envolvente e intuitiva para os utilizadores.'
    },
    {
        id: '02',
        title: 'Vídeo',
        text: 'A nossa expertise permite-nos criar vídeos impressionantes e funcionais. Cada vídeo é cuidadosamente elaborado para garantir uma experiência visual cativante e uma navegação fácil.'
    },
    {
        id: '03',
        title: 'Realidade Virtual',
        text: 'Entregamos experiências de realidade virtual imersivas que são simultaneamente cativantes e fáceis de utilizar. A nossa abordagem integra tecnologia avançada para criar ambientes virtuais realistas e interativos, perfeitos para apresentações de arquitetura e design.'
    }
]

const Services = () => {
    return(
        <div className="services-area">
            <div className="top-title-area">
                <h1>Nós podemos ajudá-lo com</h1>
                <DotsAnimation />
            </div>
            <div className="card-area">

                {
                    data.map(({id, title, text}, index) => (
                        <div className="card" key={index}>
                            <div className="number-area">
                                <p>{id}</p>
                            </div>
                            <div className="title-area">
                                <h1>{title}</h1>
                            </div>
                            <div className="text-area">
                                <p>{text}</p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Services