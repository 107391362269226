import { useRef } from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';
import styles from './style.module.scss';

import Image1 from '../../assets/img/c2.jpg'
import Image2 from '../../assets/img/decimal.jpg'
import Image3 from '../../assets/img/funny.jpg'
import Image4 from '../../assets/img/google.jpg'

import Image5 from '../../assets/img/maven.jpg'
import Image6 from '../../assets/img/panda.jpg'
import Image7 from '../../assets/img/powell.jpg'
import Image8 from '../../assets/img/wix.jpg'

const slider1 = [
    {
        color: "#e3e5e7",
        image: Image1
    },
    {
        color: "#d6d7dc",
        image: Image2
    },
    {
        color: "#e3e3e3",
        image: Image3
    },
    {
        color: "#21242b",
        image: Image4
    }
]

const slider2 = [
    {
        color: "#d4e3ec",
        image: Image5
    },
    {
        color: "#e5e0e1",
        image: Image6
    },
    {
        color: "#d7d4cf",
        image: Image7
    },
    {
        color: "#e1dad6",
        image: Image8
    }
]

export default function SlidingImages() {

    const container = useRef(null);
    const { scrollYProgress } = useScroll({
        target: container,
        offset: ["start end", "end start"]
    })

    const x1 = useTransform(scrollYProgress, [0, 1], [0, 150])
    const x2 = useTransform(scrollYProgress, [0, 1], [0, -150])
    const height = useTransform(scrollYProgress, [0, 0.9], [50, 0])

    return (
        <div ref={container} className={styles.slidingImages} style={{overflow:'hidden'}}>
            <motion.div style={{x: x1}} className={styles.slider}>
                    {
                        slider1.map( (project, index) => {
                            return <div key={index} className={styles.project} style={{backgroundColor: project.color}} >
                                <div className={styles.imageContainer}>
                                   <img src={project.image} style={{ height:'100%'}} alt="" />
                                </div>
                            </div>
                        })
                    }
                </motion.div>
                <motion.div style={{x: x2}} className={styles.slider}>
                    {
                        slider2.map( (project, index) => {
                            return <div key={index} className={styles.project} style={{backgroundColor: project.color}} >
                                <div key={index} className={styles.imageContainer}>
                                <img src={project.image} style={{ height:'100%'}} alt="" />
                                </div>
                            </div>
                        })
                    }
                </motion.div>
                <div className="filter"></div>
                {/* <motion.div style={{height}} className={styles.circleContainer}>
                    <div className={styles.circle}></div>
                </motion.div> */}
        </div>
    )
}
