import { Link } from 'react-router-dom'
import './styles.scss'
import ProfileImg from '../../assets/img/ceoProfile.jpg'
import Magnetic from '../../common/Magnetic';

const LetsWork = () => {
    return(
        <div className="lets-work-area">
            <div className="title-area">
                <img src={ProfileImg} alt="" />
                <h1>Vamos trabalhar juntos</h1>
            </div>
            <div className="get-in-touch-area">
                
                <Magnetic>
                    <Link to='/contacto' className='btn'>Contacte-nos</Link>
                </Magnetic>
            </div>
            <div className="button-area">
                <a className="btn" href="mailto:info@vcvisuals.pt" target="_blank" rel="noopener noreferrer">info@vcvisuals.com</a>
                <a className="btn" href="https://wa.me/351935278505?text=Olá%20VCvisuals,%20Eu%20gostaria%20de%20saber%20mais%20sobre%20os%20teus%20serviços." target="_blank" rel="noopener noreferrer">WhatsApp</a>
            </div>
            
        </div>
    )
}

export default LetsWork