import { useEffect, useState } from 'react'
import './styles.scss'
import Rounded from '../../common/RoundedButton'
import Soon from '../Soon'
import IMG1 from '../../assets/img/decimal.jpg'
import IMG2 from '../../assets/img/funny.jpg'
import IMG3 from '../../assets/img/google.jpg'
import IMG4 from '../../assets/img/c2.jpg'
import IMG5 from '../../assets/img/maven.jpg'
import IMG6 from '../../assets/img/panda.jpg'
import IMG7 from '../../assets/img/wix.jpg'
import IMG8 from '../../assets/img/powell.jpg'

const data = [
    [
        {image: IMG1 },
        {image: IMG2 },
        {image: IMG3 },
        {image: IMG4 },
        {image: IMG8 },
        {image: IMG6 },
        {image: IMG7 },
        {image: IMG5 },
    ]
]

const WorkArea = ({setOpenImage, setImageToOpen}) => {

    const [filterIndex, setFilterIndex] = useState(1)
    const [animationClass, setAnimationClass] = useState('')

    useEffect(() => {
        if (filterIndex === 2 || filterIndex === 3) {
            setAnimationClass('animate-dots')
        } else {
            setAnimationClass('')
        }
    }, [filterIndex])

    const handleOpenImage = (image) => {
        setImageToOpen(image)
        setOpenImage(true)
    }

    return (
        <div className="work-area">

            <div className="title-area">
                <h1>Criando projetos de alto nível</h1>
            </div>
            <div className="filter-area">
                <Rounded onClick={() => setFilterIndex(1)}>
                    <p style={{color:'white'}} >Renderização</p>
                </Rounded>
                <Rounded onClick={() => setFilterIndex(2)}>
                    <p style={{color:'white'}} >Vídeo</p>
                </Rounded>
                <Rounded onClick={() => setFilterIndex(3)}>
                    <p style={{color:'white'}} >Realidade Virtual</p>
                </Rounded>
            </div>

            {filterIndex === 1 && 
                <div className="images-area">

                    {
                        data[0].map(({image}, index) => (
                            <div className="image" key={index} onClick={() => handleOpenImage(image)}>
                                <img src={image} alt="Image description" />
                            </div>
                        ))
                    }

                </div>
            }

            {filterIndex === 2 && (
                <>
                    <Soon />
                    <p style={{color:'white', marginTop:'10px'}}>Área de Vídeo em construção</p>
                    <div className={`dots-container ${animationClass}`}>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </>
            )}
            {filterIndex === 3 && (
                <>
                    <Soon />
                    <p style={{color:'white', marginTop:'10px'}}>Área de Realidade Virtual em construção</p>
                    <div className={`dots-container ${animationClass}`}>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                    </div>
                </>
            )}
        </div>
    )
}

export default WorkArea