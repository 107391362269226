import { Link } from 'react-router-dom'
import './styles.scss'
import { useEffect, useState } from 'react';
import Magnetic from '../../common/Magnetic'

const Footer = () => {

    const currentYear = new Date().getFullYear();

    const [horaPortugal, setHoraPortugal] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            const horaPortugal = getHoraPortugal();
            setHoraPortugal(horaPortugal);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function getHoraPortugal() {
        const horaLocal = new Date();
        const options = { timeZone: 'Europe/Lisbon' };
        const horaPortugal = horaLocal.toLocaleTimeString('pt-PT', options);
        return horaPortugal;
    }

    return(
        <div className="footer-area">
            <div className="left">
                <div className="version">
                    <small>versão</small>
                    <Magnetic>
                        <p>{currentYear} © Edition</p>
                    </Magnetic>
                    
                </div>
                <div className="local-time">
                    <small>Hora local</small>
                    <Magnetic>
                        <p>{horaPortugal} Portugal</p>
                    </Magnetic>
                    
                </div>
            </div>
            <div className="right">
                <div className="socials">
                    
                    <small>Rede sociais</small>

                    <div className="links">

                        <Magnetic>
                            <Link className='link' target='blank_' to='https://www.instagram.com/vcstudio_3d?igsh=ZmxqY3QxaHh2NzZu'>Instagram</Link>
                        </Magnetic>

                        {/* <Magnetic>
                            <Link className='link' to=''>Behance</Link>
                        </Magnetic> */}

                        {/* <Magnetic>
                            <Link className='link' to=''>Youtube</Link>
                        </Magnetic> */}

                        {/* <Magnetic>
                            <Link className='link' to=''>LinkedIn</Link>
                        </Magnetic> */}
                        
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Footer