import VideoLoop from '../VideoLoop'
import './styles.scss'

const Header = () => {
   
    return (
        <div className="header-area">
            <div className="filter-for-bg"></div>
           <VideoLoop />
        </div>
    )
}

export default Header
