import './styles.scss'
import IMAGE from '../../assets/img/ceoProfile.jpg'
import Services from '../Services'



const AboutArea = () => {
    return (
        <div className="about-area">
            <div className="header-title-area">
                <h1>Ajudando marcas a prosperar no mundo digital</h1>
            </div>
            <div className="ceo-profile-area">
                <div className="text-area">
                    <p>Ajudo empresas de todo o mundo com soluções sob medida. A cada projeto, levo meu trabalho para novos horizontes, sempre colocando a qualidade em primeiro lugar.</p>
                    <small>Sempre a explorar</small>
                </div>
                <div className="image-area">
                    <img src={IMAGE} alt="" />
                </div>
            </div>
            <Services />
        </div>
    )
}

export default AboutArea