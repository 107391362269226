import { useEffect, useState } from 'react'
import './styles.scss'

const DotsAnimation = () => {

    const [animationClass, setAnimationClass] = useState('')

    useEffect(() => {
       
        setAnimationClass('animate-dots')
        
    }, [])

    return( 
        <div className={`dots-container ${animationClass}`}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            {/* <div className="dot"></div> */}
        </div>
    )
}

export default DotsAnimation