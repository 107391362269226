import { AnimatePresence } from "framer-motion";
import LetsWork from "../components/LetsWork";
import PreloaderWork from "../components/PreloaderWork";
import { useEffect, useState } from "react";
import WorkArea from "../components/WorkArea";

export default function Work({setOpenImage, setImageToOpen}) {

    const [isLoading, setIsLoading] = useState(true)

    useEffect( () => {
        (
          async () => {
              const LocomotiveScroll = (await import('locomotive-scroll')).default
              const locomotiveScroll = new LocomotiveScroll();
    
              setTimeout( () => {
                setIsLoading(false);
                document.body.style.cursor = 'default'
                window.scrollTo(0,0);
              }, 2000)
          }
        )()
    }, [])

    return(
        
        <>
            <AnimatePresence mode='wait'>
                {isLoading && <PreloaderWork />}
            </AnimatePresence> 
            <WorkArea setOpenImage={setOpenImage} setImageToOpen={setImageToOpen}/>
            <LetsWork />
        </>
    )
}