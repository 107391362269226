import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LetsWork from "../components/LetsWork";
import Preloader from "../components/Preloader";
import { AnimatePresence } from 'framer-motion';
import SlidingImages from "../components/SlidingImages";
import Projects from "../components/Projects";
import Services from '../components/Services'

export default function Home({setOpenImage, setImageToOpen}) {

    const [isLoading, setIsLoading] = useState(true);

    useEffect( () => {
        (
          async () => {
              const LocomotiveScroll = (await import('locomotive-scroll')).default
              const locomotiveScroll = new LocomotiveScroll();
    
              setTimeout( () => {
                setIsLoading(false);
                document.body.style.cursor = 'default'
                window.scrollTo(0,0);
              }, 2000)
          }
        )()
    }, [])

    return(
        
        <>
            <AnimatePresence mode='wait'>
                {isLoading && <Preloader />}
            </AnimatePresence>
            <Header />
            <Services />
            <Projects setOpenImage={setOpenImage} setImageToOpen={setImageToOpen}/>
            <SlidingImages />
            <LetsWork />
        </>
    )
}

