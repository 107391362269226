import './styles.scss'
import { IoIosCloseCircle } from "react-icons/io";

const ImageOpen = ({setOpenImage ,imageToOpen}) => {

    const handleCloseImage = () => {
        setOpenImage(false)
    }

    return (
        <div className="image-open-area" >
            <div className="image-area">
                <button onClick={handleCloseImage}>
                    <IoIosCloseCircle className='icon'/>
                </button>
                <img src={imageToOpen} alt="" />
                <div className="info-area">
                    <p>image</p>
                    <p>1/1</p>
                </div>
            </div>
           
            
        </div>
    )
}

export default ImageOpen