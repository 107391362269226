import { Link } from 'react-router-dom'
import './styles.scss'
import Magnetic from '../../common/Magnetic'

const Nav = () => {
    return(
        <div className="top-links-area">
            <div className="left">
                <Magnetic>
                    <p>
                        <Link to='/' style={{color:'white'}}>© vcvisuals </Link> 
                    </p>
                </Magnetic>
            </div>
            <div className="right">

                <Magnetic>
                    <Link className='link' to='/trabalho'>Trabalho</Link>
                </Magnetic>
                <Magnetic>
                    <Link className='link' to='/sobre'>Sobre</Link>
                </Magnetic>
                <Magnetic>
                    <Link className='link' to='/contacto'>Contacto</Link>
                </Magnetic>
                
            </div>
        </div>
    )
}

export default Nav