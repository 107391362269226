import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Work from "./pages/Work";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Nav from './components/Nav'
import Footer from "./components/Footer";
import ImageOpen from "./components/ImageOpen";
import { useState } from "react";

function App() {

  const [openImage, setOpenImage] = useState(false)
  const [imageToOpen, setImageToOpen] = useState(null)

  return (
    <>
      {openImage &&
        <ImageOpen setOpenImage={setOpenImage} imageToOpen={imageToOpen}/>
      }
      <Nav />
      <Routes>
        <Route path="/" element={<Home setOpenImage={setOpenImage} setImageToOpen={setImageToOpen}/>} />
        <Route path="/trabalho" element={<Work setOpenImage={setOpenImage} setImageToOpen={setImageToOpen} />} />
        <Route path="/sobre" element={<About />} />
        <Route path="/contacto" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
