import { AnimatePresence } from "framer-motion";
import LetsWork from "../components/LetsWork";
import PreloaderAbout from "../components/PreloaderAbout";
import { useEffect, useState } from "react";
import AboutArea from "../components/AboutArea";

export default function About() {

    const [isLoading, setIsLoading] = useState(true)

    useEffect( () => {
        (
          async () => {
              const LocomotiveScroll = (await import('locomotive-scroll')).default
              const locomotiveScroll = new LocomotiveScroll();
    
              setTimeout( () => {
                setIsLoading(false);
                document.body.style.cursor = 'default'
                window.scrollTo(0,0);
              }, 2000)
          }
        )()
    }, [])
    return(
        
        <>
            <AnimatePresence mode='wait'>
                {isLoading && <PreloaderAbout />}
            </AnimatePresence> 
            <AboutArea />
            <LetsWork />
        </>
    )
}