import './styles.scss'
import ProfileImg from '../../assets/img/ceoProfile.jpg'
import { Link } from 'react-router-dom'
import Magnetic from '../../common/Magnetic'
import { FiArrowDownRight } from "react-icons/fi"
import emailjs from 'emailjs-com'
import { useState } from 'react'

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const FormArea = () => {

    const [form, setForm] = useState({
        name: '',
        email: '',
        organization: '',
        services: '',
        message: '',
    })

    const [status, setStatus] = useState('')

    const handleChange = (e) => {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        toastr.info('Sending...', 'Your email is being sent.', {
          closeButton: true,
          progressBar: true,
          positionClass: 'toast-top-right'
        })
    
        emailjs.send(
          'service_qjh3vwr', // Substitua pelo seu Service ID
          'template_xgsxo3u', // Substitua pelo seu Template ID
          form,
          'zCfx1vkTmkoclqo-_' // Substitua pelo seu User ID
        )
          .then(() => {
            toastr.success('Success', 'Email sent successfully!', {
              closeButton: true,
              progressBar: true,
              positionClass: 'toast-top-right'
            })
            setForm({
              name: '',
              email: '',
              organization: '',
              services: '',
              message: '',
            })
          })
          .catch((error) => {
            console.error(error)
            toastr.error('Error', 'Failed to send email.', {
              closeButton: true,
              progressBar: true,
              positionClass: 'toast-top-right'
            })
        })
    }
    
    return(
        
        <div className="form-area">
            <form onSubmit={handleSubmit}>
                <div className="title-area">
                    <h1>Vamos começar um projeto juntos</h1>
                </div>
                <div className="input-area">
                    <label><small>01</small>Qual o seu nome?</label>
                    <input type="text" name='name' value={form.name} onChange={handleChange} placeholder='João Silva *' required/>
                </div>
                <div className="input-area">
                    <label><small>02</small>Qual o seu email?</label>
                    <input type="email" name='email' value={form.email} onChange={handleChange} placeholder='joão@silva.com *' required/>
                </div>
                <div className="input-area">
                    <label><small>03</small>Qual é o nome da sua organização?</label>
                    <input type="text" name='organization' value={form.organization} onChange={handleChange} placeholder='João & Silva *'/>
                </div>
                <div className="input-area">
                    <label><small>04</small>Quais os serviços que você está procurando?</label>
                    <input type="text" name='services' value={form.services} onChange={handleChange} placeholder='Renderização, Vídeo, Realidade Virtual... *' required/>
                </div>
                <div className="input-area">
                    <label><small>05</small>Sua mensagem</label>
                    <textarea type="text" name='message' value={form.message} onChange={handleChange} placeholder='Olá VCStudio, você pode me ajudar com... *' required/>
                </div>
                <div className="button-area">
                    
                    <Magnetic>
                        <button type="submit">Enviar!</button>
                    </Magnetic>
                    
                </div>
            </form>
            <div className="contact-details">
                <div className="photo-area">
                    <img src={ProfileImg} alt="" />
                    <FiArrowDownRight className='icon'/>
                </div>
                <div className="contacts-area">
                    <h1>CONTACTO</h1>
                    <p>info@vcvisuals.com</p>
                </div>
                <div className="socials-area">
                    <h1>REDES SOCIAIS</h1>

                    <div className="links">

                        <Magnetic>
                            <p>
                                <Link className='link' target='blank_' to='https://www.instagram.com/vcstudio_3d?igsh=ZmxqY3QxaHh2NzZu'>Instagram</Link>
                            </p>
                            
                        </Magnetic>

                        {/* <Magnetic>
                            <p>
                                <Link className='link' to=''>Behance</Link>
                            </p>
                            
                        </Magnetic> */}

                        {/* <Magnetic>
                            <p>
                                <Link className='link' to=''>Youtube</Link>
                            </p>
                            
                        </Magnetic> */}

                        {/* <Magnetic>
                            <p>
                                <Link className='link' to=''>LinkedIn</Link>
                            </p>
                        </Magnetic> */}
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormArea